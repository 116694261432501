<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm id="importForm">
        <CCard>
          <CCardHeader>
            <div class="row">
              <div class="col-sm">
                <CButton
                  type="button"
                  class="btn btn-pill goBackLinkSmall"
                  @click="goBack"
                >
                  <fa-icon icon="arrow-left" size="sm" />
                  {{ $t("common.back") }}
                </CButton>
                <strong>{{ $t("smart.reviewRepo") }}</strong>
              </div>
              <div class="col-sm">
                <div class="float-right permanent-height-26px">
                  <CButton
                    v-if="!isCodeGenerationInProccess"
                    type="button"
                    class="btn btn-pill btn-color-link padding0Btn"
                    @click="codeGeneration"
                  >
                    <CIcon name="cib-atom" />
                    {{ $t("smart.codeGen") }}
                  </CButton>
                  <fa-icon
                    v-if="isCodeGenerationInProccess"
                    icon="sync"
                    size="1x"
                    class="animated"
                  />
                </div>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="row" v-if="!isUmlView">
              <div class="col-sm">
                <div class="breadcrumbItem">
                  <CButton
                    v-if="breadCrumb.length > 0"
                    type="button"
                    class="btn btn-pill goBackLink"
                    @click="filesTreeHandler('', false)"
                  >
                    ...
                  </CButton>
                  {{ getLinkBackStep() }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2 overflowAutoStyle" v-if="isVisibleMenu">
                <div
                  v-for="(item, i) in treeList"
                  :key="i"
                  class="treeListStyle"
                >
                  <CButton
                    v-if="item.name === 'chaincode.yaml'"
                    type="button"
                    class="btn btn-pill btn-color-link"
                    @click="toggleUmlView"
                  >
                    <fa-icon icon="folder" size="sm" />
                    {{ item.name }}
                  </CButton>
                  <div v-else>
                    <CButton
                      v-if="item.is_dir"
                      type="button"
                      class="btn btn-pill btn-color-link"
                      @click="filesTreeHandler(item.name, true)"
                    >
                      <fa-icon icon="folder" size="sm" />
                      {{ item.name }}
                    </CButton>
                    <CButton
                      v-else
                      type="button"
                      class="btn btn-pill btn-color-link"
                      @click="getFileSourceCode(item.path, item.name)"
                    >
                      <fa-icon icon="file-code" size="sm" />
                      {{ item.name }}
                    </CButton>
                  </div>
                </div>
              </div>
              <div :class="getViewClass()" v-if="isUmlView">
                <div class="codeStyles">
                  <div class="headerFileName umlToRight">
                    <CButton
                      type="button"
                      :class="getImgBtnStyle()"
                      @click="isImageUmlHandler"
                    >
                      <CIcon name="cib-hubspot" />
                      PUML
                    </CButton>
                  </div>
                  <div v-if="isImageUml">
                    <div
                      v-for="(item, i) in chainCodeUml"
                      :key="i"
                      class="chainCodeUmlWrapper"
                    >
                      <div>
                        <div class="umlSchemeName">{{ item.name }}</div>
                        <img :src="getDiagramImage(item.diagram_url)" />
                      </div>
                    </div>
                  </div>
                  <pre v-else>{{ selectedFileSource }}</pre>
                </div>
              </div>
              <div :class="getViewClass()" v-if="!isUmlView">
                <div class="codeStyles" v-if="fullPathName !== ''">
                  <div class="headerFileName">
                    <fa-icon icon="file-code" size="sm" class="ml-1" />
                    {{ fullPathName }}
                  </div>
                  <pre>{{ selectedFileSource }}</pre>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import utilMessages from "@/utils/messages";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SmartContractSourceCode",
  components: {},
  data() {
    return {
      treeList: [],
      selectedFileSource: "",
      fullPathName: "",
      breadCrumb: [],
      isUmlView: false,
      chainCodeUml: [],
      isImageUml: true,
      isVisibleMenu: true,
    };
  },
  async mounted() {
    await this.getUml();
    await this.filesTreeHandler("", true);
  },
  methods: {
    ...mapMutations(["updateCodeGenerationStatus"]),
    ...mapActions(["generateAtom", "subscribe", "handleEndpointWsMessage"]),
    async toggleUmlView() {
      this.isUmlView = true;
      await this.getUml();
      if (!this.isImageUml) {
        await this.getFileSourceCode("", "chaincode.yaml", true);
      }
    },
    async filesTreeHandler(path, isAddPath) {
      this.isUmlView = false;
      if (path !== "" || !isAddPath) {
        this.breadCrumb = isAddPath
          ? [...this.breadCrumb, path]
          : this.breadCrumb.slice(0, this.breadCrumb.length - 1);
      }
      if (this.$route.params.id) {
        this.smartContractId = this.$route.params.id;
        try {
          const data = {
            id: this.smartContractId,
            path: this.breadCrumb.length > 0 ? this.breadCrumb.join("/") : path,
          };
          this.treeList = await this.$store.dispatch(
            "getFilesSourceTree",
            data
          );
          // reset file source;
          this.selectedFileSource = "";
          this.fullPathName = "";
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async getFileSourceCode(path, fileName, isChaincodeYaml) {
      if (!isChaincodeYaml) {
        this.isUmlView = false;
      }
      try {
        const data = {
          id: this.smartContractId,
          fileName,
          path,
        };
        this.fullPathName = `${path}/${fileName}`;
        this.selectedFileSource = await this.$store.dispatch(
          "getFileSourceCode",
          data
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getUml() {
      if (this.$route.params.id) {
        this.smartContractId = this.$route.params.id;
        try {
          const data = {
            id: this.smartContractId,
          };
          this.chainCodeUml = await this.$store.dispatch(
            "getChaincodeSourceCode",
            data
          );
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async codeGeneration() {
      if (!this.$route.params.id) {
        return;
      }

      try {
        this.updateCodeGenerationStatus(true);
        const { topic_id } = await this.generateAtom({
          id: this.$route.params.id,
        });

        const subscription = await this.subscribe({
          key: topic_id,
          handler: (data) => {
            try {
              this.handleEndpointWsMessage({ ...data, generateCode: true });
            } finally {
              subscription.unsubscribe();
            }
          },
        });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getLinkBackStep() {
      return this.breadCrumb.join("/");
    },
    getDiagramImage(img) {
      return img.replace("/uml/", "/png/");
    },
    async isImageUmlHandler() {
      if (this.isImageUml) {
        await this.getFileSourceCode("", "chaincode.yaml", true);
      }
      this.isImageUml = !this.isImageUml;
    },
    getImgBtnStyle() {
      return this.isImageUml
        ? "btn btn-pill withBorder"
        : "btn btn-pill selectedButton";
    },
    toggleMenu() {
      this.isVisibleMenu = !this.isVisibleMenu;
    },
    getViewClass() {
      return this.isVisibleMenu ? "col-sm-10" : "col-sm-12";
    },
    goBack() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters(["isCodeGenerationInProccess"]),
  },
};
</script>
<style scoped>
.treeWrapper button {
  padding: 0;
  margin-bottom: 10px;
}

.codeStyles {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.codeStyles pre {
  padding: 10px;
}

.headerFileName {
  border-bottom: 1px solid #ccc;
  width: 100%;
  background: #ddd;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.umlToRight {
  display: flex;
  justify-content: flex-end;
}

.treeListStyle button {
  padding: 0;
  white-space: nowrap;
}

.overflowAutoStyle {
  overflow: auto;
}

.breadcrumbItem {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.goBackLink {
  color: blue;
  padding-left: 0;
}

.goBackLinkSmall {
  color: blue;
  padding: 0;
  margin-right: 10px;
  height: 17px;
  line-height: 17px;
  border: none;
  vertical-align: inherit;
}

.goBackLink:focus {
  box-shadow: none;
}

.selectedButton {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
}

.withBorder {
  border: 1px solid #000;
}

.chainCodeUmlWrapper {
  padding: 10px 15px;
  overflow: auto;
}

.padding0Btn {
  padding: 0;
}

.umlSchemeName {
  padding: 12px 0 6px 6px;
}

.permanent-height-26px {
  height: 26px;
}
</style>
